import React from "react"
import { graphql } from "gatsby"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import Prism from '@atoms/Prism'
import Tags from "@projects/Tags"
import SEO from "@atoms/Seo"
import { MarkdownHtml } from "@projects/MarkdownHtml"

import * as css from "@css/layouts/l-article.module.styl"

import { gsap } from "gsap"
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)

function BlogPage({data, ...props})
{
    console.log(data)
    const { blog, default_thumbnail} = data
    const { title, tags, category, date, thumbnail, content } = blog
    const { excerpt, htmlAst } = content.childrenMarkdownRemark[0]

    const img = thumbnail ? getImage(thumbnail.gatsbyImageData) : getImage(default_thumbnail.childImageSharp.gatsbyImageData)
    const contentRef = React.useRef()

    React.useEffect(() => {
        const headlines = Array.prototype.filter.call(
            contentRef.current.childNodes[0].childNodes,
            (dom => dom.tagName !== undefined)
        )
        for (let i = 0; i < headlines.length; i++) {
            let dom = headlines[i]
            let { tagName, className } = dom
            if (tagName.match(/H/g)) {
                gsap.to(tagName, {
                    duration: 1.0,
                    scrollTrigger: {
                        trigger: dom,
                        start: "top center+=30%",
                        end: "bottom top",
                        toggleClass: { targets: dom, className: "active" }
                    }
                })
            }
        }
    })
    React.useEffect(()=>{
        const images = contentRef.current.querySelectorAll('.gatsby-resp-image-wrapper')
        for(let i = 0; i < images.length; i++){
            let dom = images[i]
            let childDom = dom.querySelector('.gatsby-resp-image-image')
            gsap.to(dom, {
                duration: 1.0,
                scrollTrigger: {
                    once: true,
                    trigger: dom,
                    start: "top center+=30%",
                    end: "bottom top",
                    toggleClass: { targets: dom, className: "active" }
                }
            })
        }
    })

    console.log(img)
    return(
        <>
            <Prism />
            <SEO
                subtitle={title}
                description={excerpt}
                image={`https:${img.images.fallback.src}`}
            />
            <article className={css.lArticle}>
                <header className={css.lArticle__header}>
                    <figure className={css.lArticle__header__thumbnail}>
                        <GatsbyImage image={img} alt="thubmanil" />
                    </figure>
                    <div className={css.lArticle__header__detail}>
                        <h1 className={css.lArticle__header__detail__title}>{title}</h1>
                        <div className={css.lArticle__header__detail__category}>
                            {/* <TransitionLink className={css.aCategory} to={`/${category}/`}>{category}</TransitionLink> */}
                            <span className={css.aCategory}>{category}</span>
                        </div>
                        {tags && <div className={css.lArticle__header__detail__tags}>
                            <Tags data={tags} />
                        </div>}

                        <p className={css.lArticle__header__detail__date}>
                            <time className={css.aDate} dateTime={date}>{date}</time>
                        </p>
                    </div>
                </header>
                <MarkdownHtml className={css.lArticle__content} htmlAst={htmlAst} ref={contentRef}/>
            </article>
        </>
    )
}

export const pagerQuery = graphql`
    fragment Thumbnail on ImageSharp {
        gatsbyImageData(
            width: 1280
            placeholder: BLURRED
            formats: [AUTO, WEBP]
            quality: 80
        )
    }
    fragment ContentfulThumbnail on ContentfulAsset {
        gatsbyImageData(
            width: 1280
            placeholder: BLURRED
            formats: [AUTO, WEBP]
            quality: 80
        )
    }
    fragment Blog on ContentfulLog {
        slug
        date(formatString: "DD MMMM, YYYY")
        title
        category
        tags
        thumbnail {
            ...ContentfulThumbnail
            localFile {
                childImageSharp {
                    ...Thumbnail
                }
            }
        }
        content {
            childrenMarkdownRemark {
                excerpt
                htmlAst
            }
        }
        fields {
            slug
        }
    }
    query BlogBySlug($slug: String!){
        blog : contentfulLog( fields: { slug: { eq: $slug } } ){
            ...Blog
        }
        default_thumbnail : file(relativePath: { eq: "img_default.png" }) {
            childImageSharp {
                ...Thumbnail
            }
        }
    }
`
export default BlogPage