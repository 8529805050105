
import React from "react"
import rehypeReact from "rehype-react"
import { TransitionLink } from "@atoms/Link"
// import { graphql, useStaticQuery } from "gatsby"
// import { globalHistory } from '@reach/router'

const renderAst = new rehypeReact({
    createElement: React.createElement,
    components: { "tslink": TransitionLink },
}).Compiler

const MarkdownHtml = React.forwardRef(({ html, htmlAst, ...props}, ref) => {
    if( html ){
        return (
            <div ref={ref} {...props} dangerouslySetInnerHTML={{ __html: html }}/>
        )
    }
    return (
        <div ref={ref} {...props}>
            { renderAst(htmlAst) }
        </div>
    )
})


export { MarkdownHtml }