// extracted by mini-css-extract-plugin
export var aBorder = "h";
export var lArticle__header__detail = "i";
export var aCategoryGray = "j";
export var aCategory = "k";
export var lArticle__header__detail__category = "l";
export var aDate = "m";
export var lArticle = "n";
export var lArticle__header = "o";
export var lArticle__header__thumbnail = "p";
export var lArticle__header__detail__title = "q";
export var lArticle__header__detail__tags = "r";
export var lArticle__header__detail__date = "s";
export var lArticle__content = "t";
export var animationMarquee = "u";